<template>
  <div class="root">
    <div class="visible-area">
      <div class="loot-area" :class="{ pulse: disabled }">
        <div class="row">
          <p>
            sektor
          </p>
          <p>
            řada
          </p>
          <p>
            sedadlo
          </p>
        </div>
        <div class="row">
          <p>
            {{ jsonItems.SECTOR }}
          </p>
          <p>
            {{ jsonItems.ROW }}
          </p>
          <p>
            {{ jsonItems.SEAT }}
          </p>
        </div>
      </div>
      <button @click="startLoot();">{{ startStop }}</button>
    </div>
    <input class="input" v-model="countDown" placeholder="s">
    <div class="connect-dot" v-if="count === 'X' || count === 0" style="background: red"></div>
    <div class="connect-dot" v-else style="background: green"></div>
  </div>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

#app {
  font-family: 'Roboto', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

ul {
  width: 250px;
  text-align: left;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: black;
}

.root {
  width: 100vw;
  height: 100vh;
  background-image: url("./assets/2024-2024-background.jpg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.visible-area {
  width: calc(100%);
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10%;
}

.loot-area {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90%;
  max-width: 1420px;
  height: 50%;
  background-image: url("./assets/BG_WHITE.webp");
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: cover;
  filter: drop-shadow(0px 0px 21px rgba(0, 0, 0, 0.45));
  border: 10px solid #FFFFFF;
  border-radius: 45px;
}

.pulse {
  animation: pulse 1s ease-out 3;
}

@-webkit-keyframes pulse {
  0% {
    transform: scale(1);
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.773);
  }

  70% {
    transform: scale(1.02);
    -webkit-box-shadow: 0 0 0 250px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(1);
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.773);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.773);
  }

  70% {
    transform: scale(1.02);
    -moz-box-shadow: 0 0 0 250px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 250px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(1);
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 96%;
  max-width: 1000px;
  height: 100%;
}

.row:nth-child(1) {
  font-size: 4rem;
  height: 20%
}

.row:nth-child(2) {
  border: black solid .75rem;
  background: linear-gradient(180deg, #C0C0C0 0%, #FFFFFF 33.33%, #FFFFFF 66.67%, #C0C0C0 100%);
  border-radius: 2rem;
  height: 60%;
  max-height: 60%;
}

.row:nth-child(2) p {
  font-size: clamp(3.5rem, 10vw, 10rem);
  width: 100%;
  height: 100%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
}

.row:nth-child(2) p:nth-child(2) {
  border-left: .75rem solid black;
  border-right: .75rem solid black;
}

.row * {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.row:nth-child(1) p {
  padding: 0;
  margin: 0;
  font-size: clamp(1.5rem, 5vw, 4rem);
  text-transform: uppercase;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  display: flex;
  align-items: center;
  text-align: center;
  color: black;
}

button {
  width: 80%;
  max-width: 357px;
  height: 100px;
  background: #FFFFFF;
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.45);
  border-radius: 33px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-size: 60px;
  line-height: 70px;
  text-align: center;
  z-index: 0;
  position: relative;
  overflow: hidden;
  color: #000000;

  display: flex;
  align-items: center;
  justify-content: center;
}

button:hover {
  transform: scale(1.05);
}

button,
button::before,
button::after {
  box-sizing: border-box;
}

button::before {
  content: '';
  position: absolute;
  z-index: -2;
  left: -50%;
  top: -50%;
  width: 200%;
  height: 200%;
  border-radius: 33px;
  background-color: #c8101f;
  background-repeat: no-repeat;
  background-size: 50% 50%, 50% 50%;
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  background-image:
    linear-gradient(#c8101f, #c8101f),
    linear-gradient(#9d9d9d, #9d9d9d),
    linear-gradient(#c8101f, #c8101f),
    linear-gradient(#9d9d9d, #9d9d9d);
  animation: rotate 4s linear infinite;
}

button::after {
  content: '';
  position: absolute;
  z-index: -1;
  left: 6px;
  top: 6px;
  border-radius: 30px;
  width: calc(100% - 12px);
  height: calc(100% - 12px);
  background: white;
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

input {
  padding: 0;
  margin: 0;
  position: absolute;
  bottom: 1rem;
  right: 2.5rem;
  width: 1rem;
  height: 1rem;
  background-color: transparent;
  border: none;
  color: white;
  text-align: center;
}

.connect-dot {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
}
</style>

<script>
import PublicGoogleSheetsParser from './js/index'

export default {
  data() {
    return {
      items: [],
      jsonItems: [],
      ID: '1Dj3LnR-E5Y3PbRw7FDTVJUKdz9liATLPqV99yBG62c8',
      count: "X",
      random: "",
      countDown: 3,
      startStop: "START",
      loop: true,
      winner: false,

      disabled: false,
      enable: true,
    }
  },
  computed: {
    parser() {
      return new PublicGoogleSheetsParser()
    },
  },
  methods: {
    warnDisabled() {
      this.disabled = true
      setTimeout(() => {
        this.disabled = false
      }, this.countDown * 1000)
    },

    async getItems(spreadsheetId) {
      this.items = await this.parser.parse(spreadsheetId)
      this.count = Object.keys(this.items).length;

      if (!this.count || this.count === 0) {
        console.log("Connection to google sheets is not correct!")
      }
    },

    async startLoot() {
      if (this.loop) {
        this.countDowner()
        this.loop = false;
        this.winner = false;
        this.startStop = this.countDown;
        this.loopCycle();
        this.disabled = false;
      }
      // else{
      //   this.loop = true;
      //   this.winner = true;
      //   this.countDown = 2;
      //   this.startStop = "START"
      // }
    },

    async loopCycle() {
      if (!this.winner) {
        setTimeout(() => {
          this.randomRow();
          this.loopCycle()
        }, 200);
      }
    },

    async countDowner() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.startStop = this.countDown
          this.countDowner()
        }, 1000)
      } else {
        this.disabled = true;
        this.loop = true;
        this.winner = true;
        this.countDown = 3;
        this.startStop = "START"
      }
    },

    async randomRow() {
      if (this.count === "X" || this.count === 0) {
        console.warn("Random is not ready for use");
      } else {
        this.random = Math.floor((Math.random() * this.count));
        console.log("Random row is correct");
        this.jsonParse();
      }
    },

    async jsonParse() {
      if (this.count === "X" || this.count === 0) {
        console.warn("JSON parsing is not ready")
      } else {
        this.jsonItems = JSON.parse(JSON.stringify(this.items[this.random]));
        console.log("Parsing is correct");
      }
    }
  },
  created: function () {
    this.getItems('1Dj3LnR-E5Y3PbRw7FDTVJUKdz9liATLPqV99yBG62c8')
  }
}
</script>
